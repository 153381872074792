import icon1EpicJourneyActive_a from "../assest/icons/vsple website design (4)/aboutus icon 1 epic journey active.png";
import icon1EpicJourneyInActive_a from "../assest/icons/vsple website design (4)/aboutus icon 1 epic journey inactive.png";
import icon2EpicJourneyActive_a from "../assest/icons/vsple website design (4)/aboutus icon 2 epic journey active.png";
import icon2EpicJourneyInActive_a from "../assest/icons/vsple website design (4)/aboutus icon 2 epic journey inactive.png";
import icon3EpicJourneyActive_a from "../assest/icons/vsple website design (4)/aboutus icon 3 epic journey active.png";
import icon3EpicJourneyInActive_a from "../assest/icons/vsple website design (4)/aboutus icon 3 epic journey inactive.png";
import icon4EpicJourneyActive_a from "../assest/icons/vsple website design (4)/aboutus icon 4 epic journey active.png";
import icon4EpicJourneyInActive_a from "../assest/icons/vsple website design (4)/aboutus icon 4 epic journey inactive.png";
import icon5EpicJourneyActive_a from "../assest/icons/vsple website design (4)/aboutus icon 5 epic journey active.png";
import icon5EpicJourneyInActive_a from "../assest/icons/vsple website design (4)/aboutus icon 5 epic journey inactives.png";
import icon6EpicJourneyActive_a from "../assest/icons/vsple website design (4)/aboutus icon 6 epic journet active.png";
import icon6EpicJourneyInActive_a from "../assest/icons/vsple website design (4)/aboutus icon 6 epic journet inactive.png";
import icon7EpicJourneyActive_a from "../assest/icons/vsple website design (4)/aboutus icon 7 epic journey active.png";
import icon7EpicJourneyInActive_a from "../assest/icons/vsple website design (4)/about us icon 7 epic journey inactive.png"

export const icon1EpicJourneyActive = icon1EpicJourneyActive_a;
export const icon1EpicJourneyInActive = icon1EpicJourneyInActive_a;

export const icon2EpicJourneyActive = icon2EpicJourneyActive_a;
export const icon2EpicJourneyInActive = icon2EpicJourneyInActive_a;

export const icon3EpicJourneyActive = icon3EpicJourneyActive_a;
export const icon3EpicJourneyInActive = icon3EpicJourneyInActive_a;

export const icon4EpicJourneyActive = icon4EpicJourneyActive_a;
export const icon4EpicJourneyInActive = icon4EpicJourneyInActive_a;

export const icon5EpicJourneyActive = icon5EpicJourneyActive_a;
export const icon5EpicJourneyInActive = icon5EpicJourneyInActive_a;

export const icon6EpicJourneyActive = icon6EpicJourneyActive_a;
export const icon6EpicJourneyInActive = icon6EpicJourneyInActive_a;

export const icon7EpicJourneyActive = icon7EpicJourneyActive_a;
export const icon7EpicJourneyInActive = icon7EpicJourneyInActive_a;
