import frame1_a from "../../../src/assest/icons/cubeFrames/Desktop - 104.png";
import frame2_a from "../../../src/assest/icons/cubeFrames/Desktop - 105.png";
import frame3_a from "../../../src/assest/icons/cubeFrames/Desktop - 106.png";
import frame4_a from "../../../src/assest/icons/cubeFrames/Desktop - 107.png";
import frame5_a from "../../../src/assest/icons/cubeFrames/Desktop - 108.png";
import frame6_a from "../../../src/assest/icons/cubeFrames/Desktop - 109.png";
import frame7_a from "../../../src/assest/icons/cubeFrames/Desktop - 110.png";
import frame8_a from "../../../src/assest/icons/cubeFrames/Desktop - 111.png";
import frame9_a from "../../../src/assest/icons/cubeFrames/Desktop - 112.png";
import frame10_a from "../../../src/assest/icons/cubeFrames/Desktop - 113.png";
import frame11_a from "../../../src/assest/icons/cubeFrames/Desktop - 114.png";
import frame12_a from "../../../src/assest/icons/cubeFrames/Desktop - 115.png";
import frame13_a from "../../../src/assest/icons/cubeFrames/Desktop - 116.png";
import frame14_a from "../../../src/assest/icons/cubeFrames/Desktop - 117.png";
import frame15_a from "../../../src/assest/icons/cubeFrames/Desktop - 118.png";
import frame16_a from "../../../src/assest/icons/cubeFrames/Desktop - 119.png";
import frame17_a from "../../../src/assest/icons/cubeFrames/Desktop - 120.png";
import frame18_a from "../../../src/assest/icons/cubeFrames/Desktop - 121.png";
import frame19_a from "../../../src/assest/icons/cubeFrames/Desktop - 122.png";
import frame20_a from "../../../src/assest/icons/cubeFrames/Desktop - 123.png";
import frame21_a from "../../../src/assest/icons/cubeFrames/Desktop - 124.png";
import frame22_a from "../../../src/assest/icons/cubeFrames/Desktop - 125.png";
import frame23_a from "../../../src/assest/icons/cubeFrames/Desktop - 126.png";
import frame24_a from "../../../src/assest/icons/cubeFrames/Desktop - 127.png";
import frame25_a from "../../../src/assest/icons/cubeFrames/Desktop - 128.png";
import frame26_a from "../../../src/assest/icons/cubeFrames/Desktop - 103.png";
import frame27_a from "../../../src/assest/icons/cubeFrames/Desktop - 52.png";
import frame28_a from "../../../src/assest/icons/cubeFrames/Desktop - 53.png";
import frame29_a from "../../../src/assest/icons/cubeFrames/Desktop - 54.png";
import frame30_a from "../../../src/assest/icons/cubeFrames/Desktop - 55.png";
import frame31_a from "../../../src/assest/icons/cubeFrames/Desktop - 56.png";
import frame32_a from "../../../src/assest/icons/cubeFrames/Desktop - 57.png";
import frame33_a from "../../../src/assest/icons/cubeFrames/Desktop - 58.png";
import frame34_a from "../../../src/assest/icons/cubeFrames/Desktop - 59.png";
import frame35_a from "../../../src/assest/icons/cubeFrames/Desktop - 60.png";
import frame36_a from "../../../src/assest/icons/cubeFrames/Desktop - 61.png";
import frame37_a from "../../../src/assest/icons/cubeFrames/Desktop - 62.png";
import frame38_a from "../../../src/assest/icons/cubeFrames/Desktop - 63.png";
import frame39_a from "../../../src/assest/icons/cubeFrames/Desktop - 64.png";
import frame40_a from "../../../src/assest/icons/cubeFrames/Desktop - 65.png";
import frame41_a from "../../../src/assest/icons/cubeFrames/Desktop - 66.png";
import frame42_a from "../../../src/assest/icons/cubeFrames/Desktop - 67.png";
import frame43_a from "../../../src/assest/icons/cubeFrames/Desktop - 68.png";
import frame44_a from "../../../src/assest/icons/cubeFrames/Desktop - 69.png";
import frame45_a from "../../../src/assest/icons/cubeFrames/Desktop - 70.png";
import frame46_a from "../../../src/assest/icons/cubeFrames/Desktop - 71.png";
import frame47_a from "../../../src/assest/icons/cubeFrames/Desktop - 72.png";
import frame48_a from "../../../src/assest/icons/cubeFrames/Desktop - 73.png";
import frame49_a from "../../../src/assest/icons/cubeFrames/Desktop - 74.png";
import frame50_a from "../../../src/assest/icons/cubeFrames/Desktop - 75.png";
import frame51_a from "../../../src/assest/icons/cubeFrames/Desktop - 76.png";
import frame52_a from "../../../src/assest/icons/cubeFrames/Desktop - 77.png";

export const frame1 = frame1_a;
export const frame2 = frame2_a;
export const frame3 = frame3_a;
export const frame4 = frame4_a;
export const frame5 = frame5_a;
export const frame6 = frame6_a;
export const frame7 = frame7_a;
export const frame8 = frame8_a;
export const frame9 = frame9_a;
export const frame10 = frame10_a;
export const frame11 = frame11_a;
export const frame12 = frame12_a;
export const frame13 = frame13_a;
export const frame14 = frame14_a;
export const frame15 = frame15_a;
export const frame16 = frame16_a;
export const frame17 = frame17_a;
export const frame18 = frame18_a;
export const frame19 = frame19_a;
export const frame20 = frame20_a;
export const frame21 = frame21_a;
export const frame22 = frame22_a;
export const frame23 = frame23_a;
export const frame24 = frame24_a;
export const frame25 = frame25_a;
export const frame26 = frame26_a;
export const frame27 = frame27_a;
export const frame28 = frame28_a;
export const frame29 = frame29_a;
export const frame30 = frame30_a;
export const frame31 = frame31_a;
export const frame32 = frame32_a;
export const frame33 = frame33_a;
export const frame34 = frame34_a;
export const frame35 = frame35_a;
export const frame36 = frame36_a;
export const frame37 = frame37_a;
export const frame38 = frame38_a;
export const frame39 = frame39_a;
export const frame40 = frame40_a;
export const frame41 = frame41_a;
export const frame42 = frame42_a;
export const frame43 = frame43_a;
export const frame44 = frame44_a;
export const frame45 = frame45_a;
export const frame46 = frame46_a;
export const frame47 = frame47_a;
export const frame48 = frame48_a;
export const frame49 = frame49_a;
export const frame50 = frame50_a;
export const frame51 = frame51_a;
export const frame52 = frame52_a;