import React, { useRef, useEffect, useState } from 'react';
import * as FrameConstants from "../constants/frames/frameConstant.js";
import "./frame.css"
import { frame } from '../common/images.js';

const framesImages = [
FrameConstants.frame1, FrameConstants.frame2, FrameConstants.frame3, FrameConstants.frame4,
FrameConstants.frame5, FrameConstants.frame6, FrameConstants.frame7, FrameConstants.frame8,
FrameConstants.frame9, FrameConstants.frame10, FrameConstants.frame11, FrameConstants.frame12,
FrameConstants.frame13, FrameConstants.frame14, FrameConstants.frame15, FrameConstants.frame16,
FrameConstants.frame17, FrameConstants.frame18, FrameConstants.frame19, FrameConstants.frame20,
FrameConstants.frame21, FrameConstants.frame22, FrameConstants.frame23, FrameConstants.frame24,
FrameConstants.frame25, FrameConstants.frame26, FrameConstants.frame27, FrameConstants.frame28,
FrameConstants.frame29, FrameConstants.frame30, FrameConstants.frame31, FrameConstants.frame32,
FrameConstants.frame33, FrameConstants.frame34, FrameConstants.frame35, FrameConstants.frame36,
FrameConstants.frame37, FrameConstants.frame38, FrameConstants.frame39, FrameConstants.frame40,
FrameConstants.frame41, FrameConstants.frame42, FrameConstants.frame43, FrameConstants.frame44,
FrameConstants.frame45, FrameConstants.frame46, FrameConstants.frame47, FrameConstants.frame48,
FrameConstants.frame49, FrameConstants.frame50, FrameConstants.frame51, FrameConstants.frame52
];

const preloadImages = (srcArray) => {
const promises = srcArray.map(src => {
return new Promise((resolve) => {
const img = new Image();
img.src = src;
img.onload = resolve;
});
});
return Promise.all(promises);
};

const ScrollControlledImages = () => {
const canvasRef = useRef(null);
const [currentFrame, setCurrentFrame] = useState(0);
const [isFixed, setIsFixed] = useState(true);
const totalFrames = framesImages.length;
useEffect(() => {
preloadImages(framesImages);

return;
}, []);

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

useEffect(() => {
    if (isSafari) {
        preloadImages(framesImages)
            .then(() => {
                window.addEventListener('scroll', handleScroll);
            })
            .catch(error => {
                console.error('Image loading failed in Safari', error);
                // Fallback or alternative handling for Safari
            });
    } else {
        preloadImages(framesImages).then(() => {
            window.addEventListener('scroll', handleScroll);
        });
    }
}, []);


const handleScroll = (event) => {
const scrollTop = window.scrollY;
const newHeight = scrollTop + 1024;
  document.documentElement.style.setProperty('--div-height', `${Math.min(newHeight, 1194)}px`);
const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
const scrollFraction = scrollTop / maxScroll;
let frameIndex = 0;
if (event.deltaY && event.deltaY < 0) { 
  console.log('Scrolled down');
  console.log(-event.deltaY," is event deltaY")
  frameIndex = Math.max((Math.floor((window.scrollY/3))),0);
  console.log(window.scrollY," is scrollY", frameIndex," is the frameIndex");
 } else {
  frameIndex = Math.min((Math.floor(window.scrollY/6)),51);
  console.log(frameIndex," while going down")
 }
 
setCurrentFrame(frameIndex);
if (window.scrollY >= 380) { setIsFixed(false); } else { setIsFixed(true); }
};

useEffect(() => {
const canvas = canvasRef.current;
const context = canvas.getContext('2d');
const img = new Image();
img.src = framesImages[currentFrame];

img.onload = () => {
context.clearRect(0, 0, canvas.width, canvas.height);
context.drawImage(img, 0, 0, canvas.width, canvas.height);
};
}, [currentFrame]);

useEffect(() => {
const handleResize = () => {
const canvas = canvasRef.current;
if(window.innerWidth >= 1200 && window.innerWidth <= 2000) {
canvas.width = 1520;
canvas.height = 1024;
} else {
canvas.width = window.innerWidth;
canvas.height = window.innerHeight;
}
};

window.addEventListener('resize', handleResize);
handleResize();

return () => {
window.removeEventListener('resize', handleResize);
};
}, []);

return (
<div onWheel={handleScroll} style={{top: 30, left: 0, overflow: 'hidden' }} className={`image-container ${isFixed ? "fixed" : "relative"}`}>
<canvas ref={canvasRef} width='1520px' height='1024px' style={{topMargin: '5rem'}}/>
</div>
);
};

export default ScrollControlledImages;